import logo from "./logo.png"

const clientData = {
    client_entity: 47,
    attorney_firm: 'Law Offices of Owen Hathaway',
    attorney_name: 'Owen Hathaway',
    attorney_number: '970-818-3052',
    attorney_email: 'owen@ohlawcolorado.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_owen_hathaway+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#194e78',
    siteLink: 'http://ohlawcolorado.com/',
    logo
}

export default clientData